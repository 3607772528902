import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { areaRoutes } from './common/routes';
import { gtag } from './services/google-analytics';
import { WebContextProvider } from './contextProvider/webContextProvider';
import PageLoader from './components/PageLoader';
import GoogleTagManager from './helper/GoogleTagManager';
export default function App() {
  return (
    <React.Fragment>
      <GoogleTagManager/>
      <WebContextProvider>
        <Routes>
          {areaRoutes.map(route => {
            return (
              <Route
                key={route.path}
                path={route.path}
                // exact={route.exact}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <route.component />
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </WebContextProvider>
    </React.Fragment>
  );
}
