import { createContext, ReactElement, useMemo, useState ,useEffect} from 'react';

export const WebContext = createContext<any>('');

interface WebContextProviderProps {
  children: ReactElement | ReactElement[];
}

export const WebContextProvider = (props: WebContextProviderProps) => {
  const { children } = props;
  const [selectedOffers, setSelectedOffers] = useState<any>([]);
  const [availableOffers, setAvailableOffers] = useState<any>(null);
  const [webContent, setWebContent] = useState<any>('');
  const [tcpaData, setTcpaData] = useState<any>('');
  const leadValues = useMemo(
    () => ({
      selectedOffers,
      setSelectedOffers,
      availableOffers,
      setAvailableOffers,
      webContent,
      setWebContent,
      tcpaData,
      setTcpaData,
    }),
    [selectedOffers,availableOffers,webContent,tcpaData],
  );

  return (
    <WebContext.Provider value={leadValues}>{children}</WebContext.Provider>
  );
};
