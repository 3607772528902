import {
    Grid,
    Box,
    Skeleton,
  } from '@mui/material';

const PageLoader = () => {
  return (
    <Box>
      <Skeleton
        sx={{ height: 220, marginBottom: 1 }}
        animation="wave"
        variant="rectangular"
      />
      <Box
        sx={{
          height: '26px',
          display: { xs: 'none', sm: 'none', md: 'flex' },
          justifyContent: 'center',
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          // height={20}
          sx={{ marginBottom: 1 }}
          width="60%"
        />
      </Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={40}
        sx={{ marginBottom: 1 }}
        width="100%"
      />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={7}>
          <Skeleton
            height={320}
            animation="wave"
            variant="rectangular"
            width="100%"
            sx={{ marginBottom: 1, borderRadius: 3 }}
          />
          <Skeleton
            height={320}
            animation="wave"
            variant="rectangular"
            width="100%"
            sx={{ marginBottom: 1, borderRadius: 3 }}
          />
          <Skeleton
           // height={320}
            animation="wave"
            variant="rectangular"
            width="100%"
            sx={{ marginBottom: 1, borderRadius: 3 , height: { xs: 10568, sm: 10568, md: 320 } }}
            
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton
            height={220}
            animation="wave"
            variant="rectangular"
            width="100%"
            sx={{ marginBottom: 3, borderRadius: 3 }}
          />

          <Skeleton
            height={380}
            animation="wave"
            variant="rectangular"
            width="100%"
            sx={{ borderRadius: 3 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ height: 320 }}
            animation="wave"
            variant="rectangular"
            width="100%"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageLoader;
